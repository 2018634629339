







































































































































import StaffProvider from '@/providers/staff.provider';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { COURSE_TYPE, USER_TYPE } from '../../enums';
import Toaster from '../../mixins/toaster.mixin';
import { Lang, ProfileData } from '../../types/general.type';

@Component
export default class UpdateStaffCoursesComponent extends mixins(Toaster) {
  checkTechnicalSupportRightCount = 0;

  pageError: string | null = null;

  serverError: Lang | null = null;

  staffNationalId: number | null = null;

  searchLoading = false;

  pageLoading = false;

  syncLoading = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nationalIdValidation: any[] = [];

  staffInfo: ProfileData | null = null;

  coursesTypes = COURSE_TYPE;

  $refs!: {
    searchForm: HTMLFormElement;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  techSupportTimeOut: any = null;

  notFoundCoursesIds: string[] = [];

  get canUpdateCourses() {
    return this.staffInfo
      && this.staffInfo.isInternalUser
      && this.staffInfo.isInternalUser !== USER_TYPE.OUTSIDE_OF_THE_SYSTEM;
  }

  mounted() {
    this.runTechnicalSupportRightValidation();
  }

  runTechnicalSupportRightValidation() {
    this.checkTechnicalSupportRightCount += 1;
    if (this.checkTechnicalSupportRightCount < 8
      && this.$store.state.app
      && this.$store.state.app.isTechnicalSupport === null) {
      this.pageLoading = true;
      this.techSupportTimeOut = setTimeout(() => {
        this.runTechnicalSupportRightValidation();
      }, 200);
    } else {
      this.checkTechnicalSupportRight();
      this.pageLoading = false;
    }
  }

  checkTechnicalSupportRight() {
    if (!(this.$store.state.app && this.$store.state.app.isTechnicalSupport)) {
      this.pageError = 'NO_SYNC_STAFF_COURSES_RIGHT';
    }
  }

  resetStaffInfo() {
    this.staffInfo = null;
    this.serverError = null;
    this.notFoundCoursesIds = [];
  }

  async getStaffData() {
    this.nationalIdValidation = [
      (v: string) => !!v || this.$t('requiredField'),
      (v: string) => (v && v.length <= 14) || `${this.$t('maxLetters')} 14 `,
      (v: string) => (v && v.length >= 14) || `${this.$t('minLetters')} 14 `,
      (v: number) => Number(v) === Math.round(v) || this.$t('validations.integerValue'),
    ];
    setTimeout(async () => {
      if (this.$refs.searchForm.validate()) {
        try {
          this.searchLoading = true;
          this.serverError = null;
          this.notFoundCoursesIds = [];
          this.staffInfo = await StaffProvider.getStaffInfo(Number(this.staffNationalId));
          this.searchLoading = false;
        } catch (err) {
          this.staffInfo = null;
          this.serverError = err as Lang;
          this.searchLoading = false;
        }
      }
    });
  }

  async updateStaffCourses(courseType: string) {
    try {
      this.notFoundCoursesIds = [];
      this.syncLoading = true;
      const { message, notFoundIbnHaithemIdCourses } = await StaffProvider.updateStaffcourses(Number(this.staffNationalId), courseType);
      if (notFoundIbnHaithemIdCourses && notFoundIbnHaithemIdCourses.length) {
        this.notFoundCoursesIds = notFoundIbnHaithemIdCourses;
      }
      this.successToaster(message, { duration: 5000 });
      this.syncLoading = false;
    } catch (error) {
      this.errorToaster(error as Lang, { duration: 5000 });
      this.syncLoading = false;
      this.notFoundCoursesIds = [];
    }
  }

  destroyed() {
    if (this.techSupportTimeOut) {
      clearTimeout(this.techSupportTimeOut);
    }
  }
}
