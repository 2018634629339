






import { Component, Vue } from 'vue-property-decorator';
import UpdateStaffCoursesComponent from '@/components/update-staff-courses/updateStaffCourses.component.vue';

@Component({
  components: {
    UpdateStaffCoursesComponent,
  },
})
export default class UpdateStaffCoursesView extends Vue { }
