/* eslint-disable @typescript-eslint/no-explicit-any */

import { Lang, ProfileData } from '@/types/general.type';
import provider from './main.provider';

class StaffProvider {
  async getStaffInfo(nationalId: number): Promise<ProfileData> {
    return provider.get(`/api/staff/getStaffInfo?nationalId=${nationalId}`);
  }

  async updateStaffcourses(
    nationalId: number,
    courseType: string,
  ): Promise<{ message: Lang; notFoundIbnHaithemIdCourses: string[] }> {
    return provider.post('/api/staff/updateStaffCourses', { nationalId, courseType });
  }
}

export default new StaffProvider();
